import { Component, OnInit } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';

import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerService } from '../app/services/spinner.service';

import { target_components } from '../../src/app/configs/target-components';
import { CUSTOM_ICON_PACK } from '@constants/custom-icon-pack';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'admin';

  constructor(
    private spinner: NgxSpinnerService,
    private spinnerService: SpinnerService,
    private iconLibraries: NbIconLibraries
  ) {
    for (let prop in target_components) {
      sessionStorage.removeItem(`lgv-${target_components[prop]}`);
    }
  }

  ngOnInit() {
    this.registerCustomIcons();
    this.spinnerService.loadingSpinner.subscribe((data) => {
      if (data === true) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
  }

  registerCustomIcons() {
    this.iconLibraries.registerSvgPack('customIconPack', {
      dashboard: CUSTOM_ICON_PACK.dashboard,
      patientsChild: CUSTOM_ICON_PACK.patientsChild,
      callLog: CUSTOM_ICON_PACK.callLog,
      followUp: CUSTOM_ICON_PACK.followUp,
      sale: CUSTOM_ICON_PACK.sale,
      patientsMother: CUSTOM_ICON_PACK.patientsMother,
      diagnosis: CUSTOM_ICON_PACK.diagnosis,
      users: CUSTOM_ICON_PACK.users,
      consultants: CUSTOM_ICON_PACK.consultants,
    });
  }
}
