import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { take, map } from "rxjs/operators";
import { SpinnerService } from "../app/services/spinner.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private spinnerService: SpinnerService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.spinnerService.loadingSpinner.next(true);
    return this.afAuth.authState.pipe(
      take(1),
      map((user) => {
        this.spinnerService.loadingSpinner.next(false);
        if (user) {
          return true;
        } else {
          return this.router.createUrlTree(["/auth/login"]);
        }
      })
    );
  }
}
