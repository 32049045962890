import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { from, Observable } from 'rxjs';
import { take, map, exhaustMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private afAuth: AngularFireAuth) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenObservable = this.afAuth.idToken;

    return tokenObservable.pipe(
      take(1),
      exhaustMap((token) => {
        if (!token) {
          return next.handle(req);
        }

        const tokenizedReq = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });

        return next.handle(tokenizedReq);
      })
    );
  }
}
