// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false
// };

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAgYe-oGt-ItjBp1_dOway3KU1YjeYjKxQ',
    authDomain: 'yin-thway.firebaseapp.com',
    databaseURL: 'https://yin-thway.firebaseio.com',
    projectId: 'yin-thway',
    storageBucket: 'yin-thway.appspot.com',
    messagingSenderId: '133902410612',
    appId: '1:133902410612:web:f0187afcda41f68e814221',
  },
  host: 'https://yt-development.herokuapp.com',
  react_admin_panel_url: 'https://dev-panel-v2-yt.web.app',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
