import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { NbAuthModule } from '@nebular/auth';
import {
  NbFirebaseAuthModule,
  NbFirebasePasswordStrategy,
} from '@nebular/firebase-auth';

import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbMenuModule,
  NbActionsModule,
  NbUserModule,
  NbCardModule,
  NbDialogModule,
  NbToastrModule,
  NbDatepickerModule,
  NbTimepickerModule,
  NbInputModule,
  NbButtonModule,
  NbAdjustableConnectedPositionStrategy,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from '../app/token.intercepter';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    NgxSpinnerModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    NbFirebaseAuthModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbLayoutModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbEvaIconsModule,
    NbActionsModule,
    NbUserModule,
    NbAuthModule.forRoot({
      strategies: [
        NbFirebasePasswordStrategy.setup({
          name: 'password',

          login: {
            redirect: {
              success: '/dashboard',
            },
          },
          logout: {
            redirect: {
              success: 'example/firebase/login',
            },
          },
          requestPassword: {
            redirect: {
              success: 'example/firebase/login',
            },
          },
          resetPassword: {
            redirect: {
              success: 'example/firebase/login',
            },
          },
        }),
      ],
      forms: {
        login: {
          redirectDelay: 500,
          strategy: 'password',
          rememberMe: true,
          socialLinks: [],
        },
        logout: {
          redirectDelay: 500,
          strategy: 'password',
        },
        requestPassword: {
          redirectDelay: 500,
          strategy: 'password',
          socialLinks: [],
        },
        resetPassword: {
          redirectDelay: 500,
          strategy: 'password',
          socialLinks: [],
        },
        validation: {
          password: {
            required: true,
          },
          email: {
            required: true,
          },
        },
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    NbAdjustableConnectedPositionStrategy,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
